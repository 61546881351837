<template>
  <div class="tokyo_tm_contact">
    <div class="fields">
      <ValidationObserver >
        <form class="contact_form" id="cont-form" ref="form" @submit.prevent="sendEmail">
          <div class="first">
            <ul>
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ errors }"
              >
                <li>
                  <input
                  name="name"
                    v-model="name"
                    type="text"
                    placeholder="Name"
                    autocomplete="off"
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </li>
              </ValidationProvider>

              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <li>
                  <input
                    name="email"
                    type="email"
                    rules="required|email"
                    v-model="email"
                    placeholder="email"
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </li>
              </ValidationProvider>
              <ValidationProvider
                name="message"
                rules="required"
                v-slot="{ errors }"
              >
                <li>
                  <textarea
                  name="message"
                    v-model="message"
                    placeholder="Message"
                  ></textarea>
                  <span class="inpur-error">{{ errors[0] }}</span>
                </li>
              </ValidationProvider>
            </ul>
          </div>
          <div class="tokyo_tm_button">
            <button type="submit" class="white-fill-bg fill-black">
              Send Message
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <!-- END FIELDS -->
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate";
import emailjs from '@emailjs/browser';

export default {
   components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
     name: '',
     email: '',
     message: ''
    };
  },

   methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm('service_4u8cxfm', 'template_hnrn3jc', e.target,
        'user_GMPFd7JZoqG60GtqSgnED', {
          name: this.name,
          email: this.email,
          message: this.message
        })

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      this.name = '',
      this.email = '',
      this.message = ''
      
    },
  },
};
</script>
