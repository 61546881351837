<template>
  <div class="container">
    <div class="positon-relative">
      <div class="shane_tm_title">
        <div class="title_flex">
          <div class="left">
            <span>Portfolio</span>
            <h3>Creative Portfolio</h3>
          </div>
        </div>
      </div>
      <!-- End shane_tm_title -->
      <div class="portfolio_filter">
        <ul>
          <li>
            <a
              v-on:click="activetab = 1"
              :class="[activetab === 1 ? 'active' : '']"
              >All</a
            >
          </li>
          <!-- End All for tabcontent 1 -->
          <li>
            <a
              v-on:click="activetab = 2"
              :class="[activetab === 2 ? 'active' : '']"
              >Branding</a
            >
          </li>
          <!-- End Branding tabcontent 2 -->
          <li>
            <a
              v-on:click="activetab = 3"
              :class="[activetab === 3 ? 'active' : '']"
              >ECommerce</a
            >
          </li>
          <!-- End ECommerce tabcontent 3 -->
          <li>
            <a
              v-on:click="activetab = 4"
              :class="[activetab === 4 ? 'active' : '']"
              >Website</a
            >
          </li>
          <!-- End Mix tabcontent 4 -->
        </ul>
      </div>
      <!-- End .portfolio-filter -->
    </div>

    <div v-if="activetab === 1" class="tabcontent">
      <div class="portfolio_list">
        <ul class="gallery_zoom">
          <CoolLightBox :items="allItems" :index="index" @close="index = null">
          </CoolLightBox>
          <li v-for="(image, imageIndex) in allItems" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry shane_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
      </div>

      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 1 -->

    <div v-if="activetab === 2" class="tabcontent">
      <div class="portfolio_list">
        <ul>
          <CoolLightBox
            :items="brandingItems"
            :index="index"
            @close="index = null"
          >
          </CoolLightBox>
          <li v-for="(image, imageIndex) in brandingItems" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry tokyo_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
        <!-- End .portfolio_list -->
      </div>
    </div>
    <!-- End .tabcontent 2 -->

    <div v-if="activetab === 3" class="tabcontent">
      <div class="portfolio_list">
        <ul>
          <CoolLightBox
            :items="ecommerceItems"
            :index="index"
            @close="index = null"
          >
          </CoolLightBox>
          <li v-for="(image, imageIndex) in ecommerceItems" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry tokyo_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
        <!-- End .portfolio_list -->
      </div>
    </div>
    <!-- End .tabcontent 3 -->

    <div v-if="activetab === 4" class="tabcontent">
      <div class="portfolio_list">
        <ul>
          <CoolLightBox
            :items="websiteItems"
            :index="index"
            @close="index = null"
          >
          </CoolLightBox>

          <li v-for="(image, imageIndex) in websiteItems" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry tokyo_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
        <!-- End .portfolio_list -->
      </div>
    </div>
    <!-- End .tabcontent 4 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activetab: 1,
      allItems: [
        {
          src: require("../../assets/img/portfolio/logocart2.png"),
          thumb: require("../../assets/img/portfolio/logocart2.png"),
          portfolioName: "Logo Cart",
          portfolioType: "Branding",
        },
        {
          src: require("../../assets/img/portfolio/gojersey2.png"),
          thumb: require("../../assets/img/portfolio/gojersey2.png"),
          portfolioName: "GoJersey Store",
          portfolioType: "ECommerce",
        },
        {
          src: require("../..//assets/img/portfolio/blueage2.png"),
          thumb: require("../../assets/img/portfolio/blueage2.png"),
          portfolioName: "BlueAge Marketing",
          portfolioType: "Branding",
        },
        {
          src: require("../../assets/img/portfolio/checkbullish2.png"),
          thumb: require("../../assets/img/portfolio/checkbullish2.png"),
          portfolioName: "CheckBullish",
          portfolioType: "Website",
        },
        {
          src: require("../../assets/img/portfolio/bowring2.png"),
          thumb: require("../../assets/img/portfolio/bowring2.png"),
          portfolioName: "Bowring Wish",
          portfolioType: "Ecommerce",
        },
        {
          src: require("../..//assets/img/portfolio/essentialhw2.png"),
          thumb: require("../..//assets/img/portfolio/essentialhw2.png"),
          portfolioName: "Essential Homewares",
          portfolioType: "ECommerce",
        },
        {
          src: require("../..//assets/img/portfolio/marvin2.png"),
          thumb: require("../../assets/img/portfolio/marvin2.png"),
          portfolioName: "Mavin Records",
          portfolioType: "Website",
        },
        {
          src: require("../../assets/img/portfolio/treten2.png"),
          thumb: require("../../assets/img/portfolio/treten2.png"),
          portfolioName: "Treten Networks",
          portfolioType: "Website",
        },
        {
          src: require("../../assets/img/portfolio/safehaven2.png"),
          thumb: require("../../assets/img/portfolio/safehaven2.png"),
          portfolioName: "SafeHaven Support",
          portfolioType: "Website",
        },
      ],
      brandingItems: [
        {
          src: require("../../assets/img/portfolio/logocart2.png"),
          thumb: require("../../assets/img/portfolio/logocart2.png"),
          portfolioName: "Logo Cart",
          portfolioType: "Branding",
        },
        {
          src: require("../..//assets/img/portfolio/blueage2.png"),
          thumb: require("../../assets/img/portfolio/blueage2.png"),
          portfolioName: "BlueAge Marketing",
          portfolioType: "Branding",
        },
      ],
      ecommerceItems: [
        {
          src: require("../../assets/img/portfolio/gojersey2.png"),
          thumb: require("../../assets/img/portfolio/gojersey2.png"),
          portfolioName: "GoJersey Store",
          portfolioType: "ECommerce",
        },
        {
          src: require("../../assets/img/portfolio/bowring2.png"),
          thumb: require("../../assets/img/portfolio/bowring2.png"),
          portfolioName: "Bowring Wish",
          portfolioType: "Ecommerce",
        },{
          src: require("../..//assets/img/portfolio/essentialhw2.png"),
          thumb: require("../..//assets/img/portfolio/essentialhw2.png"),
          portfolioName: "Essential Homewares",
          portfolioType: "ECommerce",
        },
      ],
      websiteItems: [
        {
          src: require("../../assets/img/portfolio/checkbullish2.png"),
          thumb: require("../../assets/img/portfolio/checkbullish2.png"),
          portfolioName: "CheckBullish",
          portfolioType: "Website",
        },
        {
          src: require("../..//assets/img/portfolio/marvin2.png"),
          thumb: require("../../assets/img/portfolio/marvin2.png"),
          portfolioName: "Mavin Records",
          portfolioType: "Website",
        },
        {
          src: require("../../assets/img/portfolio/treten2.png"),
          thumb: require("../../assets/img/portfolio/treten2.png"),
          portfolioName: "Treten Networks",
          portfolioType: "Website",
        },
        {
          src: require("../../assets/img/portfolio/safehaven2.png"),
          thumb: require("../../assets/img/portfolio/safehaven2.png"),
          portfolioName: "SafeHaven Support",
          portfolioType: "Website",
        },
      ],
      index: null,
    };
  },
};
</script>

<style lang="scss" scoped></style>
