<template>
  <div class="shane_tm_section">
    <div class="shane_tm_skills">
      <div class="container">
        <div class="skills_inner">
          <div class="left">
            <div class="shane_tm_title">
              <h3>Highlight of Skills and Development Tools</h3>
            </div>
            <div class="text">
              <p>
                 I configure, build, and deploy automations on AWS & Azure utilizing continuous integration and continuous delivery (CI/CD) pipelines. I have over 2 years experience creating solutions and deployments that are scalable, resilient, efficient, fault-tolerant, and sustainable using decoupled architectures such as SQS, Lambda, ECS, CloudFront, ALB, ElastiCache, API Gateway, S3, and DynamoDB. 
              </p>
            </div>
          </div>
          <!-- End .left -->

          <div class="right">
            <div class="tokyo_progress">
              <div class="progress_inner">
                <span>
                  <span class="label">Cloud Services & Deployments</span>
                  <span class="number">90%</span>
                </span>
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width:90%"></div>
                  </div>
                </div>
              </div>
              <!-- End .progress_inner -->

              <div class="progress_inner">
                <span>
                  <span class="label">Docker & Terraform</span>
                  <span class="number">85%</span>
                </span>
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width:85%"></div>
                  </div>
                </div>
              </div>
              <!-- End .progress_inner -->

              <div class="progress_inner">
                <span>
                  <span class="label">WordPress & Shopify</span>
                  <span class="number">90%</span>
                </span>
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width:90%"></div>
                  </div>
                </div>
              </div>
              <!-- End .progress_inner -->

              <div class="progress_inner">
                <span>
                  <span class="label">JavaScript & Python</span>
                  <span class="number">85%</span>
                </span>
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width:85%"></div>
                  </div>
                </div>
              </div>
              <!-- End .progress_inner -->

            </div>
          </div>
          <!-- End .right -->
        </div>
      </div>
      <!-- End .conainer -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
