<template>
  <hooper :settings="hooperSettings">
    <slide v-for="(tesimonial, i) in testimonialContent" :key="i">
      <ul>
        <li class="item">
          <div class="texts">
            <p>
              {{ tesimonial.desc }}
            </p>
            <div class="author">
              <div class="image">
                <div
                  class="main"
                  :style="{ backgroundImage: 'url(' + tesimonial.imgSrc + ')' }"
                ></div>
              </div>
              <div class="short">
                <h3 class="author">
                  <span>{{ tesimonial.name }}</span>
                </h3>
                <h3 class="job">
                  <span>{{ tesimonial.designation }}</span>
                </h3>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </slide>
  </hooper>
</template>

<script>
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
export default {
  components: {
    Hooper,
    Slide,
  },
  data() {
    return {
      testimonialContent: [
        {
          name: "Olufunmbi Bamigbade",
          designation: "Director, SHMH Support",
          imgSrc: require("../../assets/img/about/avatar2.png"),
          desc: `Juwon listened to everything we needed and wanted in our new website, then delivered it! Our new website is beautiful, exciting and easy to use for me and our audiences. I was listened to and respected through every step. We couldn’t be happier with our new website!`,
        },
        {
          name: "Larry Beckett",
          designation: "CEO, GoJersey Store",
          imgSrc: require("../../assets/img/about/avatar6.png"),
          desc: `We were very pleased with the overall development process for our website. Not only did the website exceed our expectations but the added value provided regarding internet marketing strategy was exceptional. Our website has transformed the image of our brand and is the key introductory point for new and potential clients.`,
        },
        {
          name: "Richard Edward",
          designation: "Product Manager",
          imgSrc: require("../../assets/img/about/avatar3.png"),
          desc: `Juwon Ajana & his team are honest, genuine, creative & rare. Their design and overhaul of products provided global visibility and credibility our small business needed for growth. Within six months, the teams SEO work catapulted our product line into top page rankings. As a result, our business has reaped the rewards of the work provided. Thank you all!`,
        },
        {
          name: "Olivia Davis",
          designation: "Consultant",
          imgSrc: require("../../assets/img/about/avatar4.png"),
          desc: `The design and web work has provided a vast improvement for our site visits, service inquiries and ease of maintaining. After two years I still receive compliments on the clean and attractive design, easy navigation, and concise information. Our thanks to the team for hearing our needs and then designing a top notch website for us.`,
        },
      ],
      hooperSettings: {
        infiniteScroll: true,
        wheelControl: false,
        autoPlay: true,
        mouseDrag: false,
        transition: 800,
        playSpeed: 5000,
        itemsToShow: 1,
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
