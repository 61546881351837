<template>
  <ul class="social social-default position-relative">
    <li v-for="(social, i) in socialList" :key="i">
      <a :href="social.link" target="_blank" rel="noreferrer"
        ><img class="svg" :src="social.src" alt="social"
      /></a>
    </li>
  </ul>
  <!-- End. social -->
</template>

<script>
export default {
  data() {
    return {
      socialList: [
        {
          link: "https://www.linkedin.com/in/oluwajuwon-ajana-aab77431/",
          src: require("../assets/img/svg/social/linkedin-svg.svg"),
        },
        {
          link: "https://twitter.com/nigerian_boi/",
          src: require("../assets/img/svg/social/twitter.svg"),
        },
        {
          link: "https://www.instagram.com/mr.ajana/",
          src: require("../assets/img/svg/social/instagram.svg"),
        },

        {
          link: "https://dribbble.com/ajanalarry/",
          src: require("../assets/img/svg/social/dribbble.svg"),
        },
        {
          link: "https://github.com/AjanaLarry/",
          src: require("../assets/img/svg/social/github-svg.svg"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
