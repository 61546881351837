import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from "../views/all-home-version/MainDemo.vue";
import MainDemo from "../views/all-home-version/MainDemo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MainDemo",
    meta: {
      title: "Oluwajuwon Ajana",
    },
    component: MainDemo,
  },
  {
    path: "/*",
    name: NotFound,
    meta: {
      title: "Oluwajuwon Ajana",
    },
    component: () => import("../views/all-home-version/MainDemo.vue"),
  },
];
const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.afterEach((to) => {
    if (to.meta && to.meta.title) {
        document.title =
            to.meta.title + " | Portfolio";
    }
});

export default router;