<template>
  <div class="map_wrap">
    <iframe 
      id="gmap_canvas"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2883.539270982272!2d-79.25925968376568!3d43.720122979119026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4ce462df61433%3A0x9ede0af667f75ba7!2s38%20Linden%20Ave%2C%20Scarborough%2C%20ON%20M1K%203H5!5e0!3m2!1sen!2sca!4v1656952873130!5m2!1sen!2sca" frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
    ></iframe>
  </div>
  <!-- End Google Map -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
