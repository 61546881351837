var render, staticRenderFns
import script from "./News.vue?vue&type=script&lang=js&"
export * from "./News.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c725cf0",
  null
  
)

/* custom blocks */
import block0 from "./News.vue?vue&type=custom&index=0&blockType=div&class=details"
if (typeof block0 === 'function') block0(component)
import block1 from "./News.vue?vue&type=custom&index=1&blockType=transition&name=fade"
if (typeof block1 === 'function') block1(component)
import block2 from "./News.vue?vue&type=custom&index=2&blockType=li"
if (typeof block2 === 'function') block2(component)
import block3 from "./News.vue?vue&type=custom&index=3&blockType=li"
if (typeof block3 === 'function') block3(component)

export default component.exports