<template>
  <div class="about_inner">
    <div class="left">
      <div class="image">
        <img src="../../assets/img/placeholders/1-1.jpg" alt="placeholder" />
        <div
          class="main"
          :style="{ backgroundImage: 'url(' + aboutImg + ')' }"
        ></div>
      </div>
      <!-- End image  -->
    </div>
    <!-- End left -->

    <div class="right">
      <div class="shane_tm_title">
        <span>About Me</span>
        <h3>
          Cloud Solutions Architect & Developer based in Toronto
        </h3>
      </div>
      <div class="text">
        <p>
          I'm a creator, a cloud enthusiast and a web developer passionate about using technology to create simple solutions to complex challenges. I design sustainable architectures, develop reliable infrastructures, and deploy distributed systems that guarantee operational excellence and optimize cost. I am interested in creating integrations that seamlessly deploys, and manage cloud-based solutions.
        </p>
      </div>
      <div class="shane_tm_button">
        <a :href="cv" download>
          Download CV
        </a>
      </div>
    </div>
    <!-- End right -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      aboutImg: require("../../assets/img/about/4.png"),
      cv: require("../../assets/img/resume/oluwajuwon_resume.png"),
    };
  },
};
</script>
